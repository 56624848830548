@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap");
* {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  overflow-x: hidden;
}

/* nav */
#topnav {
  color: #FFFFFF;
  display: flex;
  padding: 20px;
  text-align: right;
}
#topnav #logo {
  width: 50%;
  font-weight: 600;
  font-size: 28px;
  margin-right: 30px;
  text-align: left;
}
#topnav #logo img {
  width: 30px;
  position: relative;
  top: 1px;
}
#topnav #logo #menu li {
  position: relative;
  top: 6px;
  margin-right: 8px;
}
#topnav #logo #menu li a {
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
}

#menu {
  width: 75%;
  padding-right: 0px;
}

#button-container {
  width: 50%;
}

#menu-button,
#close-nav-menu {
  background: none;
  border: none;
}

#hamburger-icon,
#close-nav-menu {
  font-size: 33px;
  position: relative;
  top: 2px;
  right: 2px;
  border: none;
  color: #FFFFFF;
}

#mobile-menu {
  width: 100%;
  background-color: #1a1a18;
  z-index: 1;
}
#mobile-menu a {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 7.5vw;
  margin-bottom: 30px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

#menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #1a1a18;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  margin-top: -78px;
}

#mobile-menu ul {
  padding-top: 20vh;
}
#mobile-menu li {
  text-decoration: none;
  display: block;
  text-align: center;
  margin: 7vh 0px;
  font-weight: 100;
}
#mobile-menu li img {
  max-width: 12vw;
  margin-bottom: 2vh;
  display: inline-block;
}

#close-nav-menu {
  z-index: 2;
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 53px;
}

#menu {
  display: none;
}

#menu-container {
  width: 50%;
  color: #FFFFFF;
}

#search-bar {
  position: relative;
  top: -1px;
}

#footer {
  text-align: center;
  background: black;
  padding: 60px 30px 30px 30px;
  border-top: 1px solid #373737;
}
#footer h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #d9d9d9;
  text-align: center;
}
#footer #footer-faculties {
  margin-bottom: 60px;
}
#footer a,
#footer p {
  color: #c2c2c2;
}
#footer a:hover,
#footer p:hover {
  cursor: pointer;
  color: white;
}
#footer li {
  list-style-type: none;
}
#footer img {
  max-width: 150px;
  margin-bottom: 25px;
}
#footer #yoobee-logo {
  margin-bottom: 35px;
}
#footer .grey-heading-divider {
  width: 50px;
  height: 1px;
  margin: 10px auto 15px auto;
  background: #373737;
}
#footer #bottom-bar {
  font-size: 12.5px;
  border-top: 1px solid #373737;
  padding-top: 15px;
  color: #828282;
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  #footer {
    text-align: left;
    padding: 60px 140px 30px 140px;
  }
  #footer img {
    max-width: 190px;
  }
  #footer #footer-section-container {
    display: flex;
  }
  #footer #footer-section-container .footer-section {
    width: 200px;
  }
  #footer #footer-section-container .footer-section:first-of-type {
    margin-right: 100px;
  }
  #footer #footer-section-container .footer-section h5 {
    text-align: left;
    margin-top: 20px;
  }
  #footer #footer-section-container .footer-section .grey-heading-divider {
    margin-left: 0;
  }
  #footer #bottom-bar {
    margin-top: 0px;
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-7px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  58% {
    transform: scale(1, 1) translateY(-3px);
  }
  65% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-7px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  58% {
    transform: scale(1, 1) translateY(-3px);
  }
  65% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.s {
  -webkit-animation: bounce 1.5s ease infinite;
          animation: bounce 1.5s ease infinite;
}

.u {
  -webkit-animation: bounce 1.5s ease infinite 0.1s;
          animation: bounce 1.5s ease infinite 0.1s;
}

.r {
  -webkit-animation: bounce 1.5s ease infinite 0.2s;
          animation: bounce 1.5s ease infinite 0.2s;
}

.p {
  -webkit-animation: bounce 1.5s ease infinite 0.3s;
          animation: bounce 1.5s ease infinite 0.3s;
}

.r2 {
  -webkit-animation: bounce 1.5s ease infinite 0.4s;
          animation: bounce 1.5s ease infinite 0.4s;
}

.i {
  -webkit-animation: bounce 1.5s ease infinite 0.5s;
          animation: bounce 1.5s ease infinite 0.5s;
}

.s2 {
  -webkit-animation: bounce 1.5s ease infinite 0.6s;
          animation: bounce 1.5s ease infinite 0.6s;
}

.e {
  -webkit-animation: bounce 1.5s ease infinite 0.7s;
          animation: bounce 1.5s ease infinite 0.7s;
}

.m {
  -webkit-animation: bounce 1.5s ease infinite 0.8s;
          animation: bounce 1.5s ease infinite 0.8s;
}

.e2 {
  -webkit-animation: bounce 1.5s ease infinite 0.9s;
          animation: bounce 1.5s ease infinite 0.9s;
}

.mark {
  -webkit-animation: bounce 1.5s ease infinite 0.95s;
          animation: bounce 1.5s ease infinite 0.95s;
}

#swiper-featured {
  transform: scale(1.2);
  padding: 15px 0;
}
#swiper-featured .swiper-slide {
  color: white;
  text-align: center;
  border-radius: 7px;
}
#swiper-featured .swiper-slide:nth-of-type(1) {
  border: 3px solid #FCEE1F;
}
#swiper-featured .swiper-slide:nth-of-type(2) {
  border: 3px solid #46C8F5;
}
#swiper-featured .swiper-slide:nth-of-type(3) {
  border: 3px solid #ea2b7b;
}
#swiper-featured .swiper-image {
  width: 100%;
  border-radius: 5px;
  margin-bottom: -4px;
}
#swiper-featured #mystery-tagline {
  position: absolute;
  top: 80%;
  text-align: center;
  width: 100%;
  font-size: 5vw;
  font-weight: 800;
}
#swiper-featured #mystery-tagline.bouncing-text {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

#student-spotlight-description {
  position: absolute;
  top: 10vw;
  right: -30vw;
  text-align: left;
  width: 30vw;
}
#student-spotlight-description h4 {
  margin-bottom: 30px;
  font-size: 2.5vw;
}
#student-spotlight-description svg {
  position: relative;
  top: 3px;
}
#student-spotlight-description p {
  margin-bottom: 15px;
  font-size: 18px;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  background: #FCEE1F;
  padding: 5px 10px;
  border-radius: 5px;
}

@media screen and (min-width: 500px) {
  #swiper-featured {
    transform: scale(1);
  }
  #swiper-featured .swiper-slide:nth-of-type(1), #swiper-featured .swiper-slide:nth-of-type(2), #swiper-featured .swiper-slide:nth-of-type(3) {
    border-width: 5px;
  }
}
@media screen and (min-width: 700px) {
  #swiper-featured .swiper-image {
    width: 100%;
    border-radius: 0px;
    margin-bottom: -4px;
  }
}
@media screen and (min-width: 1000px) {
  #swiper-featured {
    padding-top: 0;
  }
  #swiper-featured .swiper-slide:nth-of-type(1), #swiper-featured .swiper-slide:nth-of-type(2), #swiper-featured .swiper-slide:nth-of-type(3) {
    border-width: 7px;
  }
  #swiper-featured #mystery-tagline {
    font-size: 2.5vw;
  }
}
/* 2k tv queries */
@media screen and (min-width: 2000px) {
  #student-spotlight-description p {
    font-size: 1.25vw;
  }
}
#modal {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 25vw 30px;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  z-index: 100;
  box-sizing: border-box;
}
#modal h1 {
  font-size: 10.5vw;
  margin-bottom: 60px;
  color: #FCEE1F;
  text-transform: uppercase;
}
#modal p {
  margin-bottom: 30px;
  padding: 20px 20px;
  background: #8338ec;
  line-height: 24px;
  text-align: left;
  border-radius: 5px;
}
#modal #modal-close {
  z-index: 2;
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 53px;
  color: white;
}
#modal #modal-close:hover {
  color: yellow;
  cursor: pointer;
}
#modal .project-mockup-image {
  width: 100%;
  margin-bottom: 60px;
}
#modal #end-divider {
  width: 10vw;
  height: 2px;
  background: rgba(255, 255, 255, 0.361);
  margin: 60px auto 47.5vw auto;
}
#modal h3 {
  margin-bottom: 15px;
  color: #FCEE1F;
}

/* Desktop queries */
@media screen and (min-width: 1000px) {
  #modal {
    padding: 18vh 15% 160px 15%;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
    text-align: left;
    box-shadow: inset 0 0 300px 100px rgba(0, 0, 0, 0.438);
  }
  #modal h1 {
    font-size: 6vw;
    line-height: 6vw;
    width: 65%;
    margin-bottom: 80px;
    color: #FCEE1F;
    text-transform: uppercase;
  }
  #modal p {
    font-size: 18px;
    padding: 0px 18% 0 0;
    background: transparent;
    line-height: 24px;
    text-align: left;
  }
  #modal #end-divider {
    margin: 0;
    background: transparent;
  }
  #modal h3 {
    margin-bottom: 30px;
    font-size: 25px;
  }
  #modal #project-flex-container {
    display: flex;
    border-bottom: 1px solid rgba(94, 94, 94, 0.8392156863);
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  #modal #project-flex-container #description-container {
    width: 30%;
    margin-right: 3%;
  }
  #modal #project-flex-container #project-gallery {
    width: 66%;
  }
  #modal #project-subheader {
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    background: #FCEE1F;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    top: -20px;
    box-shadow: 3px 3px 0px 1px #8338ec;
  }
}
h1,
h2,
h3,
h4,
p {
  text-align: center;
  color: #FFFFFF;
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

body {
  background: #1a1a18;
}

.arrow-down {
  background: none;
  border: none;
  display: block;
  margin: 0 auto;
  font-size: 45px;
  margin-bottom: -5px;
  color: #FFFFFF;
}

.no-scroll {
  overflow: hidden;
}

img {
  border-radius: 2px;
}

.hide-on-mobile {
  display: none;
}

#logo {
  width: 50px;
}

.container-lg {
  padding: 60px 30px;
}

.button {
  display: inline-block;
  font-size: 16px;
}
.button.regular {
  background: #1a1a18;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.button.yellow {
  background: #1a1a18;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  color: #1a1a18;
  background: #FCEE1F;
  font-weight: 500;
  border: none;
}
.button.go-back {
  background: #1a1a18;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #FCEE1F;
  color: #FCEE1F;
}
.button svg {
  position: relative;
  top: 2px;
}

.bubble-container {
  position: relative;
}

.bubble {
  position: absolute;
  width: 100%;
  height: calc(100vh + 100vw);
  overflow: hidden;
  z-index: -99;
}
.bubble img {
  position: absolute;
}

#bubble-1 {
  top: 0;
  left: 0;
}
#bubble-1 img {
  top: 27vw;
  right: -49vw;
}

#bubble-2 {
  top: 102.5vh;
  left: 0px;
}
#bubble-2 img {
  top: -15vw;
  left: -115vw;
}

#tagline {
  font-size: 24px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 60px;
}

.squiggle {
  display: none;
}

#main #hero {
  text-align: center;
  margin-bottom: 70px;
}
#main #hero #home-logo {
  width: 95%;
  margin-bottom: 35px;
  max-width: 580px;
}

#featured {
  margin-bottom: 60px;
  text-align: center;
}
#featured #student-spotlight {
  padding-bottom: 35px;
}

#student-spotlight-description {
  display: none;
}

.page-section h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 900;
  font-size: 10vw;
  margin-bottom: 35px;
}
.page-section h3 {
  font-size: 7vw;
  line-height: 8.5vw;
  text-decoration: none;
  margin-bottom: 30px;
  font-weight: 700;
}

#faculties {
  text-align: center;
}
#faculties .faculty-item {
  padding: 15px 0;
}
#faculties .faculty-item img {
  max-width: 75px;
  margin-bottom: 15px;
}

#page-header {
  font-size: 25vw;
  line-height: 25vw;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 900;
}

#faculty-icon-container {
  width: 100%;
  position: absolute;
  left: 0;
}
#faculty-icon-container #icon-header {
  width: 100%;
  max-width: 90vw;
  margin-bottom: 35px;
  position: relative;
  top: -16vw;
  transform: rotate(-10deg);
  left: -20vw;
  z-index: -99;
  opacity: 0.6;
}

#student-page {
  text-align: center;
}
#student-page h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 35px;
  font-weight: 900;
  font-size: 10vw;
  margin-bottom: 35px;
  color: #FCEE1F;
}
#student-page #student-name h1 {
  margin-bottom: 30px;
  font-size: 13vw;
}
#student-page #student-details {
  margin-bottom: 30px;
  position: relative;
}
#student-page #student-headshot {
  width: 100%;
  margin-bottom: 25px;
}
#student-page #desktop-display-pic {
  display: none;
}
#student-page .breadcrumbs {
  text-align: center;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  background: #FCEE1F;
  padding: 5px 10px;
  border-radius: 5px;
  position: relative;
  top: -20px;
  box-shadow: 3px 3px 0px 1px #8338ec;
}
#student-page .student-page-button {
  margin: 25px 0 30px 0;
}
#student-page .socials-container {
  margin-bottom: 60px;
}
#student-page .socials-container .social-icon {
  color: #FCEE1F;
  font-size: 8vw;
  margin-right: 10px;
}
#student-page .socials-container .social-tag {
  color: #FCEE1F;
  position: relative;
  top: -9px;
  border: 1px solid #FCEE1F;
  border-radius: 5px;
  padding: 6px;
}
#student-page video {
  background: black;
  margin-bottom: 30px;
}
#student-page.film-tv p {
  margin-bottom: 30px;
  line-height: 24px;
}
#student-page.film-tv h4 {
  font-size: 24px;
  margin-bottom: 30px;
}
#student-page #film-project-details #project-stills {
  width: 100%;
  color: white;
  text-align: left;
  padding-top: 30px;
}
#student-page #film-project-details #project-stills .photo-with-caption {
  display: block;
  border-radius: 5px;
  padding: 3px 2px;
}
#student-page #film-project-details #project-stills .photo-with-caption img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}
#student-page #film-project-details #project-stills .photo-with-caption p {
  background: rgb(53, 53, 53);
  margin-top: -3px;
  padding: 5px 8px 4px 8px;
  color: #c8c8c8;
  font-weight: 200;
  font-size: 15px;
}

.student-container {
  margin-bottom: 15px;
  text-align: center;
}
.student-container h4 {
  font-size: 4.5vw;
  position: relative;
  top: -22px;
  display: inline-block;
  margin: 0 auto;
  padding: 8px 12px;
  color: #FFFFFF;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(131, 56, 236, 0.7098039216);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}
.student-container h4.film-student {
  width: 90%;
  position: static;
  box-sizing: border-box;
}
.student-container img {
  width: 80%;
  border: 2px solid #3f3f3f;
}

.project-container .project-item {
  margin-bottom: 30px;
  text-align: center;
}
.project-container .project-image {
  width: 80%;
  border: 2px solid #3f3f3f;
  background: #3f3f3f;
  max-height: 320px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.project-container h4 {
  font-size: 4.5vw;
  position: relative;
  top: -22px;
  display: inline-block;
  margin: 0 auto;
  padding: 8px 12px;
  color: #FFFFFF;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(131, 56, 236, 0.7098039216);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

@media screen and (min-width: 530px) {
  .page-section h2 {
    font-size: 55px;
  }
  .page-section h3 {
    font-size: 35px;
    line-height: 8.5vw;
    margin-bottom: 30px;
  }
  .bubble {
    height: 160vh;
  }
  #page-header {
    font-size: 13vw;
    line-height: 11vw;
  }
}
@media screen and (min-width: 780px) {
  .container-lg {
    padding: 70px 30px;
  }
  .bubble {
    height: 170vw;
  }
  .show-mobile-menu-button {
    display: none;
  }
  #topnav #logo {
    margin-top: 0px;
    margin-bottom: -7px;
  }
  #menu {
    display: block;
    width: 90%;
  }
  #menu li {
    display: inline-block;
  }
  #menu li a {
    padding: 4px 11px;
    border-radius: 5px;
    color: black;
    margin-left: 16px;
    font-size: 18px;
    font-weight: 500;
  }
  #menu li:nth-of-type(1) a {
    background: #FCEE1F;
  }
  #menu li:nth-of-type(2) a {
    background: #46C8F5;
  }
  #menu li:nth-of-type(3) a {
    background: #ffa6cb;
  }
  #menu-container {
    display: none;
  }
  #search-bar {
    border: none;
    padding: 5px;
  }
  #submit-search {
    background: none;
    border: none;
    color: #1a1a18;
    font-size: 18px;
  }
  #submit-search svg {
    position: relative;
    left: -23px;
    top: 4px;
  }
  #home #hero img {
    width: 75vw;
  }
  #page-header {
    font-size: 10vw;
    line-height: 8vw;
  }
}
/* Desktop queries */
@media screen and (min-width: 1000px) {
  .hide-on-desktop {
    display: none;
  }
  .hide-on-mobile {
    display: block;
  }
  h1,
h2,
h3,
h4,
h5,
p {
    text-align: left;
  }
  .button {
    font-size: 18px;
  }
  .button:hover {
    cursor: pointer;
  }
  .container-lg {
    padding: 40px 80px 120px 80px;
  }
  #topnav {
    padding: 30px 40px;
  }
  #bubble-1 img {
    top: -10vw;
    right: -31vw;
  }
  #bubble-2 img {
    top: 19vw;
    left: -62vw;
  }
  .bubble {
    height: 135vw;
  }
  .bubble img {
    max-width: 1500px;
  }
  .bubble.faculty-page {
    height: 120vh;
  }
  .bubble.faculty-page img {
    max-width: 70vw;
  }
  #tagline {
    max-width: 650px;
    font-size: 28px;
    text-align: left;
    margin-bottom: 35px;
  }
  .squiggle {
    display: block;
    position: relative;
  }
  #hero-squiggle {
    transform: rotate(13deg);
    width: 28vw;
    max-width: 315px;
    left: 150px;
    top: 0.95vh;
  }
  #featured-squiggle {
    transform: rotate(13deg);
    width: 28vw;
    max-width: 315px;
    left: calc(150px + 10vw);
    top: 3.25vh;
  }
  #main #hero {
    text-align: left;
    height: auto;
    margin-bottom: 70px;
  }
  #main #hero #home-logo {
    width: 95%;
    margin-bottom: 35px;
    max-width: 40vw;
  }
  .arrow-down {
    display: none;
  }
  #faculties .faculty-row {
    display: flex;
  }
  #faculties .faculty-row a {
    width: 31%;
    border-radius: 10px;
    margin: 0 2% 35px 0;
    padding: 20px 20px;
  }
  #faculties .faculty-row .faculty-item {
    text-align: center;
    padding: 30px 0px 0px 0px;
  }
  #faculties .faculty-row .faculty-item h3 {
    text-align: center;
  }
  #faculties .faculty-row .faculty-item img {
    max-width: 180px;
  }
  #faculties .faculty-row .faculty-item .icon-container {
    height: 200px;
    margin-bottom: 25px;
  }
  #faculties .faculty-row .faculty-item .icon-container #animation-icon {
    position: relative;
    top: 13px;
  }
  #faculties .faculty-row .faculty-item .icon-container #film-icon {
    position: relative;
    top: -12px;
  }
  .page-section h2 {
    text-align: left;
    font-size: 4vw;
    letter-spacing: 1px;
    line-height: 8vw;
    margin-bottom: 35px;
    padding-top: 0px;
  }
  .page-section h2.no-bottom-margin {
    margin-bottom: 0;
  }
  .page-section h2#choose-faculty-h2 {
    text-align: center;
  }
  .page-section h3 {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
  #featured {
    text-align: left;
  }
  #featured #student-spotlight {
    padding: 35px 17px;
  }
  #featured #desktop-spotlight-info {
    display: block;
    position: absolute;
    top: 100px;
    left: 40%;
    color: white;
    font-size: 30px;
  }
  #featured #swiper-relative-positioned-container {
    position: relative;
  }
  #student-spotlight {
    width: 50%;
    margin: 0;
    margin-left: -12.45%;
  }
  #student-spotlight-description {
    display: block;
  }
  #faculty-icon-container #icon-header {
    max-width: 25vw;
    top: -4vw;
    left: 4vw;
  }
  #page-header {
    text-align: left;
  }
  #all-students-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  #all-students-container.film-students-list {
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
  #all-students-container.film-students-list .student-container {
    margin-bottom: 0px;
    text-align: left;
  }
  #all-students-container a img {
    width: 100%;
  }
  #all-students-container .student-container h4 {
    font-size: 18px;
  }
  #all-students-container .student-container h4.film-student {
    width: auto;
    background: rgba(131, 56, 236, 0.8);
  }
  #student-page {
    text-align: left;
  }
  #student-page #student-details {
    text-align: left;
  }
  #student-page .socials-container {
    text-align: left;
    margin-bottom: 0;
  }
  #student-page .socials-container .social-icon {
    font-size: 32px;
  }
  #student-page .project-item:hover {
    cursor: pointer;
  }
  #student-page #bubble-1 {
    height: 120vh;
  }
  #student-page #bubble-1 img {
    top: -28vw;
    right: -40vw;
    max-width: 100vw;
  }
  #student-page .breadcrumbs {
    z-index: 10;
    margin-bottom: 10px;
    text-align: left;
  }
  #student-page #student-name h1 {
    margin-bottom: 0px;
    font-size: 70px;
    display: inline-block;
    position: relative;
    top: -78px;
    left: 28px;
    width: 85%;
  }
  #student-page h2 {
    text-align: left;
    font-size: 40px;
    margin-bottom: 35px;
    color: #FCEE1F;
  }
  #student-page #student-headshot {
    display: none;
  }
  #student-page #desktop-student-details {
    display: flex;
    padding-bottom: 35px;
  }
  #student-page #desktop-student-details #desktop-display-pic {
    display: block;
    background-size: cover;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-right: 40px;
  }
  #student-page .project-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  #student-page .project-container h4 {
    font-size: 18px;
  }
  #student-page .project-container .project-image {
    width: 100%;
  }
  #student-page .back-button-container {
    text-align: left;
  }
  #student-page video {
    height: 75vh;
    margin-bottom: 30px;
  }
  #student-page #desktop-student-details {
    margin-bottom: 60px;
  }
  #student-page #desktop-student-details h1 {
    display: block;
    margin-bottom: 20px;
    margin-top: -10px;
    font-size: 70px;
  }
  #student-page #desktop-student-details #text-column {
    text-align: left;
    width: 100%;
    margin-top: 20px;
  }
  #student-page #film-project-details {
    display: flex;
  }
  #student-page #film-project-details .back-button-container {
    margin: 30px 0;
    text-align: left;
  }
  #student-page #film-project-details #project-text {
    width: 40%;
    transition: all 1s ease;
    padding: 30px;
    margin-top: -30px;
  }
  #student-page #film-project-details #project-text h2,
#student-page #film-project-details #project-text p {
    max-width: 25vw;
  }
  #student-page #film-project-details #project-text.is-sticky {
    width: 40%;
    position: fixed;
    top: 0;
    padding-top: 100px;
  }
  #student-page #film-project-details #project-text.is-sticky:after {
    background: #1a1a18;
    border-radius: 5%;
    content: "";
    display: block;
    height: 100%;
    left: -50%;
    position: absolute;
    top: -2%;
    width: 126%;
    z-index: -1;
  }
  #student-page #film-project-details #project-stills {
    width: 60%;
    padding-top: 0;
  }
  #student-page #film-project-details #project-stills.is-sticky {
    width: 58.35%;
    margin-left: 41.7%;
  }
}
/* Large Desktop queries */
@media screen and (min-width: 1400px) {
  .container-lg {
    padding: 40px 140px 160px 140px;
  }
  .bubble {
    height: 156vh;
  }
  .bubble img {
    max-width: 2000px;
  }
  .page-section h3 {
    font-size: 2vw;
    line-height: 2vw;
  }
  #menu li a {
    font-size: 20px;
  }
  #main #featured {
    margin-bottom: 70px;
  }
  #all-students-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(5, 1fr);
  }
  #all-students-container.film-students-list {
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
}
/* 2k tv queries */
@media screen and (min-width: 2000px) {
  .button {
    font-size: 1.25vw;
  }
  .container-lg {
    padding: 60px 120px 120px 120px;
  }
  .bubble.faculty-page {
    height: 200vh;
  }
  .bubble.faculty-page img {
    max-width: 60vw;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1a18;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(105, 105, 105);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FCEE1F;
}